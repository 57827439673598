import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Radio
} from 'semantic-ui-react'

const CardOpenEncounter = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "100%" , padding: "5px" }}>
        <div
          style={{textAlign: "right", ...(props.isEditEncounter && { display: "none" })}}>
          
          <div>
            {props.appointmentRegisterButton}
          </div>
        </div>
        <Table
          data={props.appointmentData}
          getTrProps={props.selectedAppointmentRow}
          headers=",วันที่นัด,เวลานัด,ชื่อแพทย์,ชื่อผู้ป่วย,แผนก,Orders,รายละเอียดการนัด"
          id="tb-encounterAppointment"
          keys="_checked,date,start_time,doctor_name,patient_name,division_name,order,detail"
          minRows={5}
          showPagination={false}
          style={{height: "220px", ...((props.isEditEncounter || props.useApiDashboard)&& { display: "none" })}}
          widths="50,^100,^60,^130,^130,^120,^120,^120">
        </Table>
        <Table
          data={props.appointmentData}
          getTrProps={props.selectedAppointmentRow}
          headers="วันที่นัด,เวลานัด,ชื่อแพทย์,ชื่อผู้ป่วย,แผนก"
          id="tb-encounterAppointment"
          keys="date,start_time,doctor_name,patient_name,division_name"
          minRows={5}
          showPagination={false}
          style={{height: "220px", ...((props.isEditEncounter|| !props.useApiDashboard) && { display: "none" })}}
          widths="^100,^60,^130,^130,^120">
        </Table>
        <div
          style={{textAlign: "right", paddingTop: "20px", display: "flex", justifyContent: "flex-end"}}>
          
          <div>
            {props.buttonVisitSlip}
          </div>
          <Button
            color="green"
            disabled={props.disabledOpenEncounter}
            id="btn-openEncounter"
            onClick={props.onOpenEncounter}
            style={{marginBottom: "0.5rem"}}>
            ลงทะเบียน (เปิด Encounter) เพิ่ม
          </Button>
        </div>
        <Table
          data={props.encounterList}
          getTrProps={props.rowProps}
          headers={props.headersEncounter}
          id="tb-encounterCreateNew"
          keys="_checked,number,division_name,_doctor,status_name,created,canceledDate,delete"
          minRows="5"
          showPagination={false}
          style={{height: "250px",...(props.useApiDashboard && { display: "none" })}}
          widths="50,^90,^100,^100,^100,^100,^100,100">
        </Table>
        <Table
          data={props.encounterList}
          getTrProps={props.rowProps}
          headers={props.headersEncounter}
          id="tb-encounterCreateNew"
          keys="_checked,number,division_name,doctor_name,status_name, started,delete"
          minRows="5"
          showPagination={false}
          style={{height: "250px",...(( !props.useApiDashboard) && { display: "none" })}}
          widths="50,^90,^100,^100,^100,^100,100">
        </Table>
        <Form
          className={props.showEncounterEditor? "": "hidden"}>
          <FormGroup
            inline={true}
            style={{marginTop: "1rem", padding: "0px 0 0 12px"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                แผนก
              </label>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-division"
                onChange={props.changeEncounter("division")}
                options={props.divisionOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.encounterData?.division || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{ fontWeight: "normal", minWidth: "max-content"}}>
                Episode
              </label>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-episode"
                noResultsMessage={props.noResultsMessageEpisode}
                onChange={props.changeEncounter("episode")}
                options={props.episodeOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.encounterData?.episode|| ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ padding: "0px 0 0 12px"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                แพทย์/อาจารย์ผู้ตรวจงาน
              </label>
            </FormField>
            <FormField
              inline={true}
              width={15}>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-doctor"
                onChange={props.changeEncounter("doctor")}
                options={props.doctorOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.encounterData?.doctor || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ padding: "0px 0 0 12px", display: props.hideStudent? "none":""}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                นิสิต/นักศึกษาแพทย์
              </label>
            </FormField>
            <FormField
              inline={true}
              width={15}>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeEncounter("student")}
                options={props.doctorOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.encounterData?.student || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{position: "relative", display: "flex", justifyContent:"center", minWidth: "max-content", border: "1px solid rgba(34,36,38,.15)", padding: "0.7rem", borderRadius: "3.5px", marginRight: "1rem"}}>
              <Radio
                checked={props.encounterData?.student && props.encounterData?.student_degree === "BACHELOR"}
                disabled={!props.encounterData?.student}
                label="ระดับปริญญาตรี"
                onChange={props.changeEncounter("student_degree")}
                value="BACHELOR">
              </Radio>
              <Radio
                checked={props.encounterData?.student && props.encounterData?.student_degree === "POSTGRADUATE"}
                disabled={props.encounterData?.student ? false : true}
                label="ระดับสูงกว่าปริญญาตรี"
                onChange={props.changeEncounter("student_degree")}
                value="POSTGRADUATE">
              </Radio>
              <div
                style={{position: "absolute", backgroundColor: "white", color: "red",fontSize:"x-small", bottom: "-4px",}}>
                *** กรุณาระบุ ***
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ padding: "0px 0 0 12px"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                ประเภทผู้ป่วย
              </label>
            </FormField>
            <FormField
              inline={true}
              width={15}>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-patientType"
                onChange={props.changeEncounter("patientCase")}
                options={props.patientCaseOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.encounterData?.patientCase || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ padding: "0px 0 0 12px"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                จุดสังเกตุ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={15}>
              <Input
                fluid={true}
                id="Input-remark"
                onChange={props.changeEncounter("remark")}
                value={props.encounterData?.remark || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ padding: "0px 0 0 12px"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                หมายเหตุ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={15}>
              <Input
                fluid={true}
                id="Input-note"
                onChange={props.changeEncounter("note")}
                value={props.encounterData?.note || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ padding: "0px 0 0 12px"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                โรคประจำตัว
              </label>
            </FormField>
            <FormField
              inline={true}
              width={15}>
              <Input
                fluid={true}
                id="Input-underlyingDisease"
                onChange={props.changeEncounter("underlying_disease")}
                value={props.encounterData?.underlying_disease || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{...(!props.isShowPregnancy && {display: "none"}), padding: "0px 0 0 12px"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px", fontWeight: "normal"}}>
                ประวัติการตั้งครรภ์
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.pregnancyData?.pregnancy_status === 1}
                label="ไม่ทราบ"
                onChange={props.changePregnancy("pregnancy_status")}
                value={1}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.pregnancyData?.pregnancy_status === 2}
                label="ไม่ตั้งครรภ์"
                onChange={props.changePregnancy("pregnancy_status")}
                value={2}>
              </Radio>
            </FormField>
            <FormField
              inline={true}>
              <Radio
                checked={props.pregnancyData?.pregnancy_status === 3}
                label="กำลังตั้งครรภ์"
                onChange={props.changePregnancy("pregnancy_status")}
                value={3}>
              </Radio>
            </FormField>
            <FormField
              inline={true}
              style={{...(props.pregnancyData?.pregnancy_status !== 3 && {display: "none"})}}>
              <label
                style={{fontWeight: "normal"}}>
                ระบุไตรมาส
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{...(props.pregnancyData?.pregnancy_status !== 3 && {display: "none"})}}>
              <Dropdown
                onChange={props.changePregnancy("pregnancy_period")}
                options={props.encounterData?.pregnancyPeriodOptions}
                selection={true}
                value={props.pregnancyData?.pregnancy_period}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ padding: "0px 0 0 12px"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}
              style={{display: "flex"}}>
              <div
                style={{...(!props.encounterData?.selectedEncounter?.id && { display: "none"})}}>
                {props.buttonEdit}
              </div>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardOpenEncounter

export const screenPropsDefault = {}

/* Date Time : Mon Jan 20 2025 16:42:03 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" , padding: \"5px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 16,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.encounterList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headersEncounter"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterCreateNew"
        },
        "keys": {
          "type": "value",
          "value": "_checked,number,division_name,_doctor,status_name,created,canceledDate,delete"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\",...(props.useApiDashboard && { display: \"none\" })}"
        },
        "widths": {
          "type": "value",
          "value": "50,^90,^100,^100,^100,^100,^100,100"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 30,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.selectedAppointmentRow"
        },
        "headers": {
          "type": "value",
          "value": ",วันที่นัด,เวลานัด,ชื่อแพทย์,ชื่อผู้ป่วย,แผนก,Orders,รายละเอียดการนัด"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterAppointment"
        },
        "keys": {
          "type": "value",
          "value": "_checked,date,start_time,doctor_name,patient_name,division_name,order,detail"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"220px\", ...((props.isEditEncounter || props.useApiDashboard)&& { display: \"none\" })}"
        },
        "widths": {
          "type": "value",
          "value": "50,^100,^60,^130,^130,^120,^120,^120"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", ...(props.isEditEncounter && { display: \"none\" })}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", paddingTop: \"20px\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงทะเบียน (เปิด Encounter) เพิ่ม"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOpenEncounter"
        },
        "id": {
          "type": "value",
          "value": "btn-openEncounter"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenEncounter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showEncounterEditor? \"\": \"hidden\""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\", padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 0 12px\", display: props.hideStudent? \"none\":\"\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์/อาจารย์ผู้ตรวจงาน"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "นิสิต/นักศึกษาแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดสังเกตุ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "โรคประจำตัว"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Dropdown",
      "parent": 64,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-division"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"division\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.division || \"\""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Dropdown",
      "parent": 66,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-doctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"doctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.doctor || \"\""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-patientType"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"patientCase\")"
        },
        "options": {
          "type": "code",
          "value": "props.patientCaseOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.patientCase || \"\""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Input",
      "parent": 79,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "Input-underlyingDisease"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"underlying_disease\")"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.underlying_disease || \"\""
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Input",
      "parent": 77,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "Input-note"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"note\")"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.note || \"\""
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Input",
      "parent": 75,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "Input-remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"remark\")"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.remark || \"\""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", display: \"flex\", justifyContent:\"center\", minWidth: \"max-content\", border: \"1px solid rgba(34,36,38,.15)\", padding: \"0.7rem\", borderRadius: \"3.5px\", marginRight: \"1rem\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Dropdown",
      "parent": 68,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"student\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.student || \"\""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "*** กรุณาระบุ ***"
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", backgroundColor: \"white\", color: \"red\",fontSize:\"x-small\", bottom: \"-4px\",}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Radio",
      "parent": 93,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.encounterData?.student && props.encounterData?.student_degree === \"BACHELOR\""
        },
        "disabled": {
          "type": "code",
          "value": "!props.encounterData?.student"
        },
        "label": {
          "type": "value",
          "value": "ระดับปริญญาตรี"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"student_degree\")"
        },
        "value": {
          "type": "value",
          "value": "BACHELOR"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Radio",
      "parent": 93,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.encounterData?.student && props.encounterData?.student_degree === \"POSTGRADUATE\""
        },
        "disabled": {
          "type": "code",
          "value": "props.encounterData?.student ? false : true"
        },
        "label": {
          "type": "value",
          "value": "ระดับสูงกว่าปริญญาตรี"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"student_degree\")"
        },
        "value": {
          "type": "value",
          "value": "POSTGRADUATE"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.isShowPregnancy && {display: \"none\"}), padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0 0 12px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 101,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 101,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{...(props.pregnancyData?.pregnancy_status !== 3 && {display: \"none\"})}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{...(props.pregnancyData?.pregnancy_status !== 3 && {display: \"none\"})}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการตั้งครรภ์"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Radio",
      "parent": 105,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_status === 1"
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "value": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Radio",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_status === 2"
        },
        "label": {
          "type": "value",
          "value": "ไม่ตั้งครรภ์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "value": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Radio",
      "parent": 107,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_status === 3"
        },
        "label": {
          "type": "value",
          "value": "กำลังตั้งครรภ์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_status\")"
        },
        "value": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุไตรมาส"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Dropdown",
      "parent": 109,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changePregnancy(\"pregnancy_period\")"
        },
        "options": {
          "type": "code",
          "value": "props.encounterData?.pregnancyPeriodOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.pregnancyData?.pregnancy_period"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.encounterData?.selectedEncounter?.id && { display: \"none\"})}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.appointmentRegisterButton"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonVisitSlip"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 120,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.selectedAppointmentRow"
        },
        "headers": {
          "type": "value",
          "value": "วันที่นัด,เวลานัด,ชื่อแพทย์,ชื่อผู้ป่วย,แผนก"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterAppointment"
        },
        "keys": {
          "type": "value",
          "value": "date,start_time,doctor_name,patient_name,division_name"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"220px\", ...((props.isEditEncounter|| !props.useApiDashboard) && { display: \"none\" })}"
        },
        "widths": {
          "type": "value",
          "value": "^100,^60,^130,^130,^120"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 121,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.encounterList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headersEncounter"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterCreateNew"
        },
        "keys": {
          "type": "value",
          "value": "_checked,number,division_name,doctor_name,status_name, started,delete"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\",...(( !props.useApiDashboard) && { display: \"none\" })}"
        },
        "widths": {
          "type": "value",
          "value": "50,^90,^100,^100,^100,^100,100"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "Episode"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "Dropdown",
      "parent": 123,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-episode"
        },
        "noResultsMessage": {
          "type": "code",
          "value": "props.noResultsMessageEpisode"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeEncounter(\"episode\")"
        },
        "options": {
          "type": "code",
          "value": "props.episodeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.encounterData?.episode|| \"\""
        }
      },
      "seq": 125,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardOpenEncounter",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 60
}

*********************************************************************************** */

import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

import QRCode from "react-qr-code";
import moment from "moment";

// Common
import getHtmlToPdfmake from "react-lib/appcon/common/getHtmlToPdfmake";
import { splitStringNewLine } from "../common/CommonInterface";
import { getLogoReportNResize } from "react-lib/apps/HISV3/common/CommonInterface";

// Utils
import {
  adToBeWithSetFormat,
  beToAd,
  formatDate,
  formatDatetime,
} from "react-lib/utils/dateUtils";

// Config
import CONFIG from "config/config";

// Types
type FormVisitSlipProps = {
  patient_name: string;
  hn: string;
  patient_birthdate: string;
  patient_age: string;
  coverage: string;
  items: {
    [key: string]: any;
    number: string;
    division_name: string;
    doctor_name: string;
    patient_case: string;
    note: string;
  }[];
};


const generatePDF = async (props: { value: string; size: number }) => {
  const qrCodeElement = React.createElement(QRCode, {
    value: props.value,
    size: props.size,
  });
  const qrCodeHtml = renderToStaticMarkup(qrCodeElement as any);

  let htmlToPdfmake = (await getHtmlToPdfmake()).default;

  const pdfDefinition = await htmlToPdfmake(qrCodeHtml);

  return pdfDefinition;
};

const getTable = (columns: any[], padding = { bottom: 5, top: 5 }) => {
  return {
    stack: [
      {
        table: {
          widths: ["15%", "32.5%", "32.5%", "20%"],
          body: [
            [
              columns[0],
              {
                alignment: "center",
                ...columns[1],
              },
              {
                alignment: "center",
                ...columns[2],
              },
              {
                text: columns[3],
                alignment: "center",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: () => 0,
          paddingTop: () => padding.top,
          paddingBottom: () => padding.bottom,
          hLineColor: () => "#d9d9d9",
        },
      },
    ],
  };
};

const FONT_SIZE = 7.5;
const NEW_LINE_OPTION = { fontSize: FONT_SIZE, ellipse: "" };
const FORM_NAME = `FormVisitSlip`;
const LOGO_HEIGHT = CONFIG.COMPANY === "MSK" ? 40 : 30;

const FormVisitSlip = async (props: FormVisitSlipProps) => {

  // ลบ HTML tags ออกจาก doctor order
  const stripHtmlTags = (html: string) => {
    // Replace <br/> tags with ","
    html = html.replace(/<br\s*\/?>/gi, ", ");

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    return doc.body.textContent?.trim() || "";
  };

  const formatAdmitOrder = (operating: any): string => {
    const admitDate = operating?.case?.app_admit_date || "";
    const admitTime = operating?.case?.app_admit_time || "";

    return `o Admit: ${admitDate} - ${admitTime}`.trim();
  };

  const formatAnesMethod = (text: string): string => {
    const parts = text.split(",");
    const lastPart = parts.length > 1 ? parts[parts.length - 1].trim() : "";
    const colonParts = lastPart.split(":");
    const lastColon = colonParts.length > 1 ? colonParts[1].trim() : "";

    return `o Anesthesia: ${lastColon}`;
  };

  const formatDrug = (html: string): string => {
    // Replace <br/><b> tags with ", "
    html = html.replace(/<br\s*\/?><b>/gi, ", ");

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    let plainText = doc.body.textContent || "";

    const firstCommaIndex = plainText.indexOf(",");
    if (firstCommaIndex !== -1) {
      plainText = plainText.slice(firstCommaIndex + 1).trim();
    }

    return `o Drug: ${plainText}`;
  };

  const formatOperatingOrder = (operating: any, html: string): string => {
    const teams = Array.isArray(operating?.teams) && operating.teams.length > 0 ? operating.teams[0] : null;
    const treatments = teams?.pre_operating_order_item?.operating_treatments || [];
    const otherTreatment = teams?.pre_operating_order_item?.other_treatment || "";
    const treatmentText = formatTreatment(treatments);
    const otherTreatmentText = formatOtherTreatment(otherTreatment);
    const operatingDateTime = extractOperatingDateTime(html);

    if (treatments.length > 0) {
      return `o Operating: ${operatingDateTime} - ${treatmentText}`.trim();
    }

    if (otherTreatmentText) {
      return `o Operating: ${operatingDateTime} - ${otherTreatmentText}`.trim();
    }

    return `o Operating: ${operatingDateTime}`;
  };

  const extractOperatingDateTime = (html: string): string => {
    const plainText = html.replace(/<br\s*\/?>/gi, "\n").replace(/<[^>]+>/g, "");
    const match = plainText.match(/\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}/);
    return match ? match[0] : "";
  };

  const formatTreatment = (treatments: any[]): string => {
    if (!Array.isArray(treatments) || treatments.length === 0) return "";

    if (treatments.length === 1) {
      return treatments[0]?.name_code;
    }

    return treatments.map((treatment, index) => `${index + 1}. ${treatment.name_code}`).join(", ");
  };

  const formatOtherTreatment = (text: string): string => {
    if (!text) return "";

    return text.replace("\n", " ");
  };

  const generateDoctorOrders = (item: any) => {
    const doctorOrders = item.doctor_orders_app || [];
    const operatingOrders = item.operating_orders_app || {};

    if (!doctorOrders || !Array.isArray(doctorOrders)) return null;
  
    return doctorOrders.map((order: any) => {
      const { type, summary_detail, specific_type_name } = order;
      const plainText = stripHtmlTags(summary_detail);
  
      switch (type) {
        case "treatmentorder":
          return `o Treatment: ${plainText}`;
        // issue 72477
        case "centrallaborder":
          if (specific_type_name === "Lab Blood Component") {
            return `o Blood Bank: ${plainText}`;
          } else {
            return `o Laboratory: ${plainText}`;
          }
        case "imagingorder":
          return `o X-ray: ${plainText}`;
        case "drugorder":
          return `o Drug: ${plainText}`;
        // issue 72451
        case "operatingorder":
          return formatOperatingOrder(operatingOrders, summary_detail); // plaintext: "Operating date time - treatment"
        // issue 72453
        case "admitorder": 
          return formatAdmitOrder(operatingOrders); // plaintext: "นัดหมายผ่าตัด" -> use operating orders data instead
        case "anesthesiaorder":
          return formatAnesMethod(plainText); // plaintext: "Patient name, Anesthesia Method : ...."
        case "drugoperatingorder":
          return formatDrug(summary_detail); // plaintext: "Patient name, [drug id] drug detail, ...."
        // issue 72489
        case "doctorconsultorder":
          return `o ${plainText}`; // plaintext: "Consult: ..."
        default:
          return `o Other (${type}): ${plainText}`;
      }
    }).join("\n");
  };

  const logoResize = await getLogoReportNResize(LOGO_HEIGHT, 1, FORM_NAME);
  const pdfDefinition = await generatePDF({ value: props.hn, size: 35 });
  const nameLines = splitStringNewLine(props.patient_name, {
    width: 115,
    ...NEW_LINE_OPTION,
  });
  const coverageLines = splitStringNewLine(
    `สิทธิ : ${props.coverage || "-"}`,
    {
      width: 84.5,
      ...NEW_LINE_OPTION,
    }
  );

  const pageMarginTop =
    107.5 + (Math.max(nameLines.length, coverageLines.length - 1) - 1) * 10;

  const generateTableBody = (items: any) => {
    const body: any[] = [
      [
        { text: "เลขที่อ้างอิง", alignment: "center", style: "tableHeader" },
        { text: "หน่วยตรวจ", alignment: "center", style: "tableHeader" },
        { text: "แพทย์", alignment: "center", style: "tableHeader" },
        { text: "ประเภท", alignment: "center", style: "tableHeader" }
      ]
    ];

    if (!items) return;
    
    items.map((item: any) => {
      const divisionLines = splitStringNewLine(item.division_name, {
        width: 79,
        ...NEW_LINE_OPTION,
      });
      const doctorLines = splitStringNewLine(item.doctor_name, {
        width: 82,
        ...NEW_LINE_OPTION,
      });
      const doctorOrderFormatted = generateDoctorOrders(item);
      
      // const paddingTop = Math.max(divisionLines.length, doctorLines.length) - 1;

      body.push([
        {
          text: item.number,
          alignment: "center",
          bold: true,
          fontSize: 12.5,
          margin: [0, -2.5, 0, 0],
          border: [true, true, true, false],
        },
        {
          stack: divisionLines.map((text: string) => ({
            text: text.trim(),
            alignment: "center",
            style: "tableField",
            // lineHeight: 1.2,
          })),
          border: [true, true, true, false],
        },
        {
          stack: doctorLines.map((text: string) => ({
            text: text.trim(),
            alignment: "center",
            style: "tableField",
            // lineHeight: 1.2,
          })),
          border: [true, true, true, false],
        },
        {
          text: item.patient_case || "-",
          alignment: "center",
          style: "tableField",
          border: [true, true, true, false],
        },
      ]);

      body.push([
        {
          text: `หมายเหตุ: ${item.note || "-"}`,
          noWrap: true,
          style: "tableField",
          colSpan: 4,
          margin: [10, 0, 0, 0],
          border: [false, false, false, false],
        },
        ...Array(3).fill({}),
      ]);

      if (doctorOrderFormatted) {
        body.push([
          {
            text: doctorOrderFormatted,
            preserveLeadingSpaces: true,
            style: "tableField",
            colSpan: 4,
            margin: [10, 0, 0, 0],
            border: [false, false, false, true],
          },
          ...Array(3).fill({}),
        ]);
      }
    })

    return body;
  };

  return {
    pageSize: "A6",
    pageMargins: [6.5, pageMarginTop, 6.5, 15],
    defaultStyle: {
      font: "KanitLM",
      lineHeight: 1,
      fontSize: FONT_SIZE,
    },
    header: () => {
      return {
        margin: [8.5, 0, 8.5, 10],
        stack: [
          {
            margin: !CONFIG.HIDE_COMPANY_LOGO_PRINT ? [-10, -2.5, 0, 0] : [-10, -2.5, 0, 15],
            columns: [
              !CONFIG.HIDE_COMPANY_LOGO_PRINT
                ? {
                    width: "25%",
                    stack: [{ image: "logo", width: logoResize.width, height: LOGO_HEIGHT, margin: [5, 10, 0, 0] }],
                  }
                : null,
              {
                width: !CONFIG.HIDE_COMPANY_LOGO_PRINT ? "60%" : "100%",
                margin: [5, 12, 0, 0], // original [5, 22.5, 0, 0]
                stack: [
                  {
                    text: [
                      { text: "บัตรเข้ารับการรักษา " },
                      { text: "(Visit slip)", font: "PoppinsLM" },
                    ],
                    style: "fieldHeader",
                  },
                  {
                    text: `วันที่เข้ารับบริการ ${formatDate(moment())}`,
                    marginTop: 1,
                  },
                ],
                alignment: "center",
              },
            ],
          },
          {
            margin: [5, 5, 5, 0], // original [5, 0, 5, 0]
            columns: [
              {
                width: "80%",
                columns: [
                  {
                    width: "60%",
                    stack: [
                      ...nameLines.map((text) => ({
                        text: text.trim(),
                        style: "fieldLabel",
                      })),
                      {
                        width: "100%",
                        columns: [
                          { text: "วันเกิด : ", width: 25 },
                          {
                            text: props.patient_birthdate
                              ? adToBeWithSetFormat(
                                  beToAd(props.patient_birthdate) as any,
                                  "YYYY-MM-DD",
                                  "DD MMMM YYYY",
                                  "th"
                                )
                              : "-",
                          },
                        ],
                        marginTop: 5,
                      },
                      {
                        width: "100%",
                        columns: [
                          { text: "อายุ : ", width: 25 },
                          { text: props.patient_age || "-" },
                        ],
                      },
                    ],
                  },
                  {
                    width: "40%",
                    stack: [
                      {
                        text: `HN : ${props.hn}`,
                        style: "fieldLabel",
                        marginBottom: 5,
                      },
                      ...coverageLines.map((text) => ({
                        text: text.trim(),
                      })),
                    ],
                  },
                ],
              },
              {
                margin: [12.5, -2.5, 0, 0],
                stack: [pdfDefinition],
              },
            ],
          },
        ],
      };
    },
    content: [
      {
        table: {
          widths: ["15%", "32.5%", "32.5%", "20%"],
          headerRows: 1,
          body: generateTableBody(props.items),
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: () => 0,
          paddingTop: () => 5,
          paddingBottom: () => 5,
          hLineColor: () => "#d9d9d9",
        },
      }
      //* -- Old Table
      // getTable([
      //   {
      //     text: "เลขที่อ้างอิง",
      //     alignment: "center",
      //   },
      //   { text: "หน่วยตรวจ" },
      //   { text: "แพทย์" },
      //   "ประเภท",
      // ]),
      // ...props.items.map((item) => {
      //   const divisionLines = splitStringNewLine(item.division_name, {
      //     width: 79,
      //     ...NEW_LINE_OPTION,
      //   });
      //   const doctorLines = splitStringNewLine(item.doctor_name, {
      //     width: 82,
      //     ...NEW_LINE_OPTION,
      //   });

      //   const paddingTop =
      //     Math.max(divisionLines.length, doctorLines.length) - 1;

      //   return getTable(
      //     [
      //       {
      //         stack: [
      //           {
      //             text: item.number,
      //             alignment: "center",
      //             bold: true,
      //             fontSize: 12.5,
      //             margin: [0, -2.5, 0, 0],
      //           },
      //           {
      //             text: "\u00a0",
      //           },
      //           ...Array(paddingTop)
      //             .fill("")
      //             .map(() => ({
      //               text: "\u00a0",
      //               fontSize: 5,
      //             })),
      //           {
      //             relativePosition: { x: 0, y: 0 },
      //             text: `หมายเหตุ : ${item.note || "-"}`,
      //             noWrap: true,
      //             style: "miniField",
      //             margin: [15, -7.5, 0, 0],
      //           },
      //           {
      //             text: generateDoctorOrders(item.doctor_orders || []),
      //             style: "miniField",
      //             margin: [15, 5, 0, 0],
      //           },
      //           {
      //             text: "\u00a0",
      //           },
      //         ],
      //       },
      //       {
      //         stack: divisionLines.map((text) => ({
      //           text: text.trim(),
      //         })),
      //       },
      //       {
      //         stack: doctorLines.map((text) => ({
      //           text: text.trim(),
      //         })),
      //       },
      //       item.patient_case || "-",
      //     ],
      //     { top: 6.5, bottom: 7.5 }
      //   );
      // }),
    ],
    footer: () => {
      return {
        text: `Print Date/Time : ${formatDatetime(moment())}`,
        alignment: "right",
        style: "miniField",
        marginRight: 7.5,
        font: "PoppinsLM",
      };
    },
    images: {
      logo: origin + logoResize.src,
    },
    styles: {
      fieldHeader: {
        fontSize: 9,
        bold: true,
      },
      fieldLabel: {
        fontSize: 8.5,
        bold: true,
      },
      miniField: {
        fontSize: 6.5,
      },
      tableHeader: {
        fontSize: 7,
      },
      tableField: {
        fontSize: 6.5,
        lineHeight: 0.8,
      }
    },
  };
};

export default FormVisitSlip;
